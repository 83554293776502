import { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import ZayaraForumFooter from "./components/Footer";
import InnerPageHeroSection from "./components/InnerPageHeroSection";
import { forumGroupsList } from "./static-data";
import { useSBSState } from "context/global";
import Locale from "translations";
import { CalenderIcon, ClockIcon, MapIcon } from "./components/Icons";
import moment from "moment";

export default function ZayaraForumFocusGroupPage() {
	const { umarhForum } = Locale;

	const { locale } = useSBSState();
	const { group_id } = useParams();
	const pageData = forumGroupsList.find((group) => group.url === group_id);

	useLayoutEffect(() => {
		window.scrollTo({
			top: 0,
		});
	}, []);

	const title = pageData.title?.[locale] || pageData.title.en;
	const discussion_topics = pageData.discussion_topics?.[locale] || pageData.discussion_topics.en;
	const speakerData = pageData.speaker;

	return (
		<main className="zayara-forum inner-page">
			<InnerPageHeroSection title={title} />

			<section className="landing-groups-container">
				<div className="container">
					<div className="row mx-0">
						<div className="col-md-8 col-12">
							<h2 className="title group-title">{umarhForum.aboutFocusGroup}</h2>
							<p className="description">{pageData.description?.[locale] || pageData.description.en}</p>
						</div>
						<div className="col-md-4 col-12">
							<img src={pageData.image} alt={title} className="img-fluid d-none d-md-block" />
						</div>
					</div>

					<div className="discussion_topics mt-md-5 mt-3">
						<h3 className="title"> {umarhForum.discussionTopics}</h3>
						<ul className="d-flex flex-column justify-content-center align-items-center gap-10">
							{discussion_topics.map((topic) => {
								return (
									<li
										key={topic}
										className="rounded-lg px-2 text-center  w-100 border"
										style={{ maxWidth: "700px", borderColor: "#DDDCDC", paddingBlock: "10px" }}>
										{topic}
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</section>

			<section className="speaker-section">
				<div className="container">
					<article className="speaker-content">
						<h3 className="header-title">{umarhForum.focusGroupModerator}</h3>
						<div className="speaker-card">
							{/* speaker image */}
							<div className="img-container">
								<img src={speakerData.image} alt={speakerData.name[locale]} />
							</div>
							<div className="speaker-addtiontion-data">
								{/* speaker info */}
								<div className="d-flex flex-column justify-content-between gap-10">
									<h4 className="speaker-name">{speakerData.name[locale]}</h4>
									<p className="text-light-grey">{speakerData.job_title[locale]}</p>

									<div className="d-flex flex-wrap align-items-center" style={{ color: "#707070" }}>
										<p className="d-flex align-items-center border-end mt-2">
											<span style={{ color: "#D59F4C" }}>
												<MapIcon />
											</span>
											<span className="mx-1">{speakerData.location[locale]}</span>
										</p>
										<p className="d-flex align-items-center border-end mt-2">
											<CalenderIcon />
											<span className="mx-1">
												{moment(speakerData.group_date.from).locale(locale).format("DD MMMM Y")}
											</span>
										</p>

										<p className="d-flex align-items-center mt-2">
											<ClockIcon />
											<span className="mx-1">
												{moment(speakerData.group_date.from).locale(locale).format("HH:mm")} -{" "}
												{moment(speakerData.group_date.to).locale(locale).format("HH:mm")}
											</span>
										</p>
									</div>
								</div>

								{/* speaker logos */}
								<div className="d-flex flex-wrap align-items-center mt-2 gap-10">
									{speakerData.logos.map((logo) => {
										return (
											<span className="logo-box" key={logo.alt}>
												<img src={logo.src} alt={logo.alt} />
											</span>
										);
									})}
								</div>
							</div>
						</div>
					</article>
				</div>
			</section>

			<ZayaraForumFooter group={pageData.url} />
		</main>
	);
}

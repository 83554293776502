import axios from "axios";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import validate, { isFormValid } from "helpers/validate";
import useFetchCountries from "hooks/useFetchCountries";
import { useEffect, useMemo, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useLocation } from "react-router-dom";
import { uploadFile } from "services/auth";
import Locale from "translations";
import ZayaraForumFooter from "./components/Footer";
import InnerPageHeroSection from "./components/InnerPageHeroSection";
import PhoneInput from "./components/PhoneInput";
import UploadImageInput from "./components/UploadImageInput";
import { forumGroupsList } from "./static-data";
import { useSBSState } from "context/global";


export default function ZayaraForumAcceptInvitation() {
  const { umarhForum, requests, productsBuilder, operationAccounting, activity, Service_Builder } = Locale;
  const { locale } = useSBSState();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");
  const history = useHistory();

  const [countries] = useFetchCountries();
  const mappedCountries = useMemo(() => {
    return countries.map(country => {
      return {
        ...country,
        name: country?.names?.[locale]
      }
    })
  }, [countries, locale]);

  const [formState, setFormState] = useState({
    ticket_name: null,
    name: "",
    company_name: "",
    job_title: "",
    country_id: null,
    phone_code: null,
    phone_number: "",
    email: "",
    image: null
  });

  const [errors, setErrors] = useState({});


  // ** functions
  function handleIputsChanges({ key, value }) {
    let formStateClone = { ...formState, [key]: value }
    if (key !== "image") {
      setErrors({
        ...errors,
        ...validate(
          { name: key, value: value },
          { required: true, email: key === "email" }
        ),
      });
    }
    setFormState(formStateClone)
  }

  async function handleUpload(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file?.name);
    formData.append("bucket", "zayara-forum");
    const response = await uploadFile(formData);
    if (response.data.data.uuid) {
      return response.data.data.uuid;
    }
  }

  function checkFormErrors() {
    let submitError = {}
    Object.keys(formState).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: formState[key] },
          { required: key !== "image", email: key === "email" }
        ),
      }
    });
    setErrors(() => submitError)
    return submitError;
  }

  async function submitForm(e) {
    e.preventDefault();
    let formHasErrors = !isFormValid(checkFormErrors());
    if (formHasErrors) return;
    const imageUUID = formState?.image instanceof File ? await handleUpload(formState?.image) : null;
    const requestData = {
      ...formState,
      country_id: formState.country_id?.id,
      ticket_name: formState?.ticket_name?.name,
      image: imageUUID
    }
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/v1/safa-event/tickets/accept-recommendation/${token}`, requestData);
    if (res.status === 200 || res.status === 201) {
      store.addNotification({
        title: Service_Builder.Success,
        message: umarhForum.registrationDoneSuccessfully,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      history.push("/umrah-forum")
    }
  }

  const getApplicantData = async (token) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/v1/safa-event/tickets/accept-recommendation/${token}`)
      .then((res) => res)
      .catch((err) => err);
  };

  async function fetchApplicantData() {
    const res = await getApplicantData(token)
    if (res.status === 200) {
      const selectedGroup = forumGroupsList.find(group => group.title?.ar === res?.data?.data?.ticket_name || group.title?.en === res?.data?.data?.ticket_name);
      const data = res?.data?.data;
      setFormState({
        ticket_name: selectedGroup ? { ...selectedGroup, label: selectedGroup.title?.[locale], name: selectedGroup.title?.[locale] } : null,
        name: data?.name,
        company_name: data?.company_name,
        job_title: data?.job_title,
        country_id: { id: data?.country_id, name: data?.country_name },
        phone_code: data?.phone_code,
        phone_number: data?.phone_number,
        email: data?.email,
        image: data?.image,
      })
    } else {
      setTimeout(() => {
        history.push("/umrah-forum")
      }, 1000);
    }
  }

  useEffect(() => {
    fetchApplicantData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <main className="zayara-forum inner-page form-page">
      <InnerPageHeroSection title={umarhForum.acceptTheInvitation} />
      <section className="landing-groups-container">
        <div className="container form-container">
          <h2 className="form-title mb-3 font-weight-normal" style={{ color: "#242424" }}>{umarhForum.registerMyAttendance}</h2>
          <p style={{ color: "#282828" }}>{umarhForum.registerNowReserveYourPlaceFocusGroup}</p>

          <form className="row mx-0" style={{ rowGap: "16px" }} onSubmit={submitForm}>
            {/* ticket_name */}
            <div className="col-12 my-3">
              <SelectField
                hasLabel={false}
                placeholder={requests.select}
                label={umarhForum.focusGroup + "*"}
                name="ticket_name"
                id="ticket_name"
                value={formState.ticket_name?.label}
                options={forumGroupsList.map(group => ({ id: group.url, label: group.title?.[locale], name: group.title?.[locale] }))}
                onChange={(e) => handleIputsChanges({ key: "ticket_name", value: e })}
                color={errors?.ticket_name?.required ? "danger" : ""}
                errors={errors?.ticket_name}
              />
            </div>

            {/* name */}
            <div className="col-md-4 col-12">
              <TextField
                type="text"
                label={productsBuilder.Name + " *"}
                placeholder={umarhForum.enterYourName}
                value={formState.name}
                name="name"
                id="name"
                onChange={(e) => handleIputsChanges({ key: "name", value: e.target.value })}
                color={errors?.name?.required ? "danger" : ""}
                errors={errors?.name}
              />
            </div>

            {/* company_name */}
            <div className="col-md-4 col-12">
              <TextField
                label={umarhForum.company + " *"}
                placeholder={umarhForum.enterCompanyName}
                value={formState.company_name}
                name="company_name"
                id="company_name"
                onChange={(e) => handleIputsChanges({ key: "company_name", value: e.target.value })}
                color={errors?.company_name?.required ? "danger" : ""}
                errors={errors?.company_name}
              />
            </div>

            {/* job_title */}
            <div className="col-md-4 col-12">
              <TextField
                label={umarhForum.jobTitle + " *"}
                placeholder={umarhForum.enterJobHere}
                value={formState.job_title}
                name="job_title"
                id="job_title"
                onChange={(e) => handleIputsChanges({ key: "job_title", value: e.target.value })}
                color={errors?.job_title?.required ? "danger" : ""}
                errors={errors?.job_title}
              />
            </div>

            {/* country */}
            <div className="col-md-4 col-12 mt-3">
              <SelectField
                label={operationAccounting.country + " *"}
                placeholder={requests.select}
                name="country_id"
                id="country_id"
                value={formState.country_id?.name}
                options={mappedCountries}
                onChange={(e) => handleIputsChanges({ key: "country_id", value: e })}
                color={errors?.country_id?.required ? "danger" : ""}
                errors={errors?.country_id}
              />
            </div>


            {/* phone */}
            <div className="col-md-4 col-12 mt-3 phone">
              <PhoneInput
                phoneError={errors?.phone_number}
                phoneECoderror={errors?.phone_code}
                onCodeChange={(e) => { handleIputsChanges({ key: "phone_code", value: e?.phone_code }) }}
                onNumberChange={(e) => handleIputsChanges({ key: "phone_number", value: e.target.value })}
                phone_code={formState.phone_code}
                phone_number={formState.phone_number}
                countries={mappedCountries}
              />
            </div>

            {/* email */}
            <div className="col-md-4 col-12 mt-3">
              <TextField
                label={activity.email + " *"}
                placeholder={umarhForum.enterYourEmail}
                value={formState.email}
                name="email"
                id="email"
                onChange={(e) => handleIputsChanges({ key: "email", value: e.target.value })}
                color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
                errors={errors?.email}
              />
            </div>

            {/* image */}
            <div className="col-12 mt-4">
              <UploadImageInput
                imageName={formState.image?.name}
                onChange={(e) => handleIputsChanges({ key: "image", value: e.target.files[0] })}
              />
            </div>

            <div className="col-12 d-flex justify-content-end mt-5">
              <button className="btn main-btn form-btn">{umarhForum.registerNow}</button>
            </div>
          </form>
        </div>
      </section>
      <ZayaraForumFooter hideButtons={true} />
    </main>
  )
}
